import React, {lazy, memo} from "react";
import {Route ,Switch} from "react-router-dom";

const Report = lazy(() => import('../report_page/Main'));
const Top = lazy(() => import('../top_page/Main'));

function Routing(props) {
    return (
        <Switch>
            <Route path="/report/:name">
                <Report />
            </Route>
            <Route path="/">
                <Top />
            </Route>
        </Switch>
    );
}

export default memo(Routing);
